import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2560.000000 2560.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M11927 17780 c-241 -5 -256 -8 -257 -43 0 -18 100 -199 126 -228 8
-9 44 -61 79 -115 34 -55 76 -114 91 -133 16 -19 37 -52 46 -74 10 -22 32 -55
48 -74 17 -18 37 -50 45 -71 9 -20 24 -46 35 -57 10 -11 30 -42 46 -70 15 -27
40 -65 55 -83 16 -18 38 -50 49 -72 11 -21 24 -41 29 -44 5 -3 20 -27 34 -53
34 -65 132 -215 214 -328 17 -24 39 -58 49 -77 9 -18 36 -63 60 -99 24 -37 44
-71 44 -76 0 -5 30 -35 67 -67 l67 -58 1481 3 c1119 2 1503 0 1575 -9 119 -16
222 -36 280 -57 25 -9 70 -21 100 -26 70 -13 118 -29 150 -50 14 -9 57 -26 97
-39 39 -12 81 -31 92 -41 11 -11 45 -28 75 -39 30 -12 69 -32 88 -45 18 -14
47 -31 64 -40 18 -8 45 -25 60 -38 16 -13 55 -40 87 -60 31 -20 57 -41 57 -46
0 -5 16 -16 36 -26 46 -22 129 -100 144 -134 6 -15 29 -38 50 -51 22 -14 43
-33 46 -42 3 -9 23 -31 45 -48 21 -18 39 -37 39 -44 0 -7 18 -29 40 -50 22
-20 40 -41 40 -47 0 -5 18 -29 40 -52 21 -24 49 -65 60 -93 12 -27 30 -58 40
-69 10 -11 31 -47 46 -80 15 -33 37 -76 50 -96 12 -19 29 -58 39 -85 9 -27 27
-67 39 -89 28 -49 41 -86 71 -213 13 -53 30 -115 38 -137 56 -149 60 -792 6
-988 -11 -40 -32 -124 -48 -187 -15 -63 -42 -144 -60 -179 -18 -36 -40 -87
-48 -114 -8 -27 -28 -70 -44 -97 -16 -26 -29 -50 -29 -54 0 -8 -51 -86 -67
-104 -7 -7 -24 -36 -38 -64 -14 -28 -36 -64 -48 -79 -12 -16 -44 -59 -72 -96
-27 -37 -72 -91 -100 -120 -27 -29 -57 -59 -65 -68 -51 -55 -207 -198 -264
-240 -37 -27 -82 -62 -100 -78 -19 -15 -58 -38 -88 -51 -30 -13 -67 -35 -84
-48 -16 -14 -49 -32 -74 -42 -25 -10 -67 -30 -95 -46 -27 -16 -77 -36 -110
-45 -33 -9 -78 -27 -100 -40 -46 -26 -124 -48 -235 -65 -41 -6 -100 -20 -130
-30 -115 -38 -198 -40 -1980 -40 l-1732 0 -21 26 c-12 15 -39 45 -59 67 -21
23 -38 46 -38 52 0 6 -15 29 -33 51 -19 21 -64 85 -100 140 -37 56 -74 107
-82 114 -8 7 -28 36 -44 64 -17 28 -42 63 -56 76 -15 14 -36 43 -47 65 -12 22
-29 49 -39 60 -21 23 -49 61 -113 155 -24 36 -53 74 -64 86 -11 11 -28 36 -37
55 -10 19 -27 43 -40 54 -12 11 -29 36 -39 55 -9 19 -30 51 -47 70 -17 19 -42
56 -54 81 -13 25 -31 51 -39 59 -9 7 -23 27 -31 44 -8 17 -27 45 -41 61 -15
17 -41 53 -59 80 -18 28 -41 57 -50 65 -9 7 -25 30 -35 50 -10 20 -26 43 -35
50 -9 8 -27 35 -40 61 -14 26 -41 67 -62 92 -21 24 -54 66 -73 91 -78 104
-103 116 -139 70 -12 -15 -21 -31 -21 -36 0 -5 -13 -28 -28 -51 -30 -44 -62
-109 -62 -124 0 -5 -12 -25 -26 -44 -14 -19 -36 -54 -48 -79 -12 -25 -33 -65
-48 -90 -15 -25 -35 -67 -46 -95 -19 -46 -48 -99 -118 -215 -15 -25 -57 -104
-93 -175 -36 -72 -76 -143 -87 -158 -12 -15 -34 -57 -49 -94 -15 -38 -32 -74
-38 -82 -12 -14 -59 -101 -124 -231 -21 -41 -45 -84 -55 -94 -10 -11 -27 -47
-38 -80 -11 -34 -30 -68 -41 -76 -11 -8 -34 -44 -50 -80 -16 -35 -36 -71 -43
-80 -8 -8 -27 -46 -42 -85 -15 -38 -35 -79 -45 -91 -11 -11 -31 -49 -45 -84
-14 -36 -38 -81 -53 -100 -15 -19 -34 -51 -41 -70 -8 -19 -27 -55 -42 -79 -15
-24 -34 -65 -43 -91 -9 -26 -24 -55 -34 -65 -11 -9 -29 -40 -41 -68 -13 -29
-35 -70 -51 -92 -15 -22 -38 -66 -49 -97 -12 -32 -25 -58 -30 -58 -5 0 -23
-30 -40 -67 -18 -38 -42 -81 -54 -98 -13 -16 -31 -52 -41 -80 -10 -27 -29 -65
-43 -83 -13 -18 -34 -59 -46 -90 -13 -31 -35 -75 -49 -96 -32 -46 -33 -57 -8
-80 18 -16 223 -17 3422 -18 1872 0 3478 2 3569 6 91 4 194 14 230 23 156 35
201 44 309 58 74 10 136 25 180 44 36 15 97 33 134 40 38 6 94 23 125 37 31
14 86 35 122 46 36 11 88 32 115 48 28 15 75 35 105 45 30 10 69 28 86 41 17
12 54 30 83 40 29 9 75 32 101 50 26 18 65 40 86 49 22 9 50 27 64 40 14 13
45 32 70 43 25 11 61 32 79 48 19 16 51 40 72 54 137 93 180 124 229 165 30
24 69 55 87 67 17 13 39 35 48 50 9 16 20 28 26 28 5 0 44 36 87 79 42 44 83
85 91 93 105 101 214 219 226 244 8 16 25 36 39 44 14 7 32 28 41 46 9 19 34
54 56 80 21 25 46 58 53 73 8 15 22 34 31 41 9 8 28 37 42 64 14 28 34 58 43
66 9 8 32 44 50 80 18 36 36 67 41 70 4 3 20 32 34 65 15 32 36 72 47 87 11
16 30 56 44 89 13 34 34 75 47 93 13 17 32 58 41 91 10 32 28 80 41 106 13 25
33 80 44 121 10 42 30 97 44 124 13 27 29 78 36 114 19 105 42 193 59 225 8
16 21 77 28 135 7 58 25 184 40 280 24 154 27 205 28 430 1 210 -3 285 -21
425 -13 94 -29 233 -37 310 -11 99 -24 168 -46 234 -17 51 -40 135 -50 187
-11 52 -29 112 -40 133 -11 22 -30 77 -42 123 -11 46 -32 99 -45 118 -13 19
-31 60 -40 90 -8 30 -32 87 -52 125 -20 39 -42 85 -49 103 -8 18 -19 38 -25
45 -7 6 -27 42 -46 80 -19 37 -38 67 -41 67 -4 0 -20 30 -36 67 -15 37 -42 83
-60 103 -17 19 -39 51 -49 70 -21 41 -46 75 -101 138 -22 26 -51 65 -64 87
-13 22 -37 54 -54 71 -17 17 -51 57 -76 88 -43 54 -140 156 -192 202 -12 11
-30 28 -38 37 -8 10 -31 32 -50 51 -19 19 -60 60 -89 90 -30 31 -61 56 -68 56
-6 0 -26 18 -44 39 -17 22 -41 42 -53 45 -12 3 -48 28 -80 56 -31 27 -68 53
-82 56 -13 3 -34 19 -45 35 -11 16 -35 37 -52 46 -18 9 -39 23 -47 33 -14 15
-45 33 -209 122 -30 16 -66 37 -80 47 -14 10 -51 26 -81 36 -67 22 -109 42
-135 65 -11 10 -47 26 -80 35 -33 10 -80 30 -105 45 -44 27 -140 60 -177 60
-10 0 -45 13 -78 29 -33 16 -104 39 -159 51 -54 11 -115 29 -135 39 -41 21
-114 37 -216 46 -38 4 -122 17 -185 30 -209 42 -384 46 -2270 48 -976 1 -1882
0 -2013 -3z"
          />
          <path
            d="M10810 17713 c-37 -48 -85 -127 -104 -168 -10 -22 -27 -49 -37 -60
-9 -11 -33 -45 -51 -76 -37 -62 -58 -97 -105 -172 -17 -29 -46 -79 -64 -112
-17 -33 -39 -69 -49 -80 -10 -11 -32 -48 -49 -82 -17 -34 -35 -65 -41 -68 -5
-3 -23 -34 -39 -69 -16 -35 -34 -67 -40 -71 -6 -3 -23 -30 -37 -58 -15 -28
-39 -67 -55 -87 -15 -19 -37 -56 -49 -82 -11 -26 -25 -50 -31 -53 -5 -4 -25
-38 -45 -77 -19 -39 -44 -79 -54 -90 -10 -11 -24 -34 -31 -52 -7 -17 -27 -49
-44 -71 -18 -22 -42 -60 -55 -85 -12 -25 -33 -57 -45 -72 -13 -15 -31 -49 -41
-75 -9 -26 -27 -59 -40 -73 -12 -14 -32 -45 -44 -70 -12 -24 -38 -66 -56 -93
-19 -27 -34 -55 -34 -61 0 -7 -15 -30 -34 -52 -18 -21 -43 -61 -55 -89 -12
-27 -33 -61 -46 -75 -13 -14 -33 -47 -44 -73 -11 -26 -31 -62 -45 -80 -13 -18
-34 -52 -46 -76 -12 -25 -28 -51 -36 -58 -7 -8 -27 -43 -44 -79 -18 -36 -38
-68 -47 -71 -8 -3 -23 -26 -34 -50 -10 -24 -29 -58 -43 -76 -13 -18 -34 -52
-46 -77 -13 -25 -33 -58 -46 -73 -13 -15 -35 -51 -49 -79 -14 -28 -37 -64 -50
-80 -14 -16 -25 -35 -25 -42 0 -7 -20 -44 -45 -82 -25 -38 -48 -80 -52 -94 -3
-14 -18 -38 -34 -55 -29 -31 -91 -134 -130 -215 -12 -25 -36 -65 -53 -90 -17
-25 -38 -61 -47 -80 -9 -19 -26 -44 -37 -56 -11 -12 -30 -41 -42 -65 -13 -24
-35 -62 -50 -84 -14 -22 -32 -54 -39 -70 -6 -17 -23 -44 -37 -60 -14 -17 -36
-50 -48 -75 -13 -25 -42 -75 -64 -112 -23 -37 -42 -70 -42 -74 0 -3 -16 -24
-35 -47 -19 -22 -35 -45 -35 -51 0 -16 -39 -87 -60 -111 -10 -11 -29 -40 -42
-65 -14 -25 -39 -69 -57 -98 -17 -29 -31 -57 -31 -62 0 -5 -11 -21 -24 -37
-13 -15 -35 -51 -49 -79 -14 -27 -33 -56 -42 -64 -9 -8 -30 -41 -47 -74 -17
-34 -36 -68 -43 -76 -7 -8 -28 -42 -46 -75 -18 -33 -43 -73 -56 -88 -12 -16
-23 -34 -23 -41 0 -7 -16 -35 -36 -62 -19 -27 -48 -76 -63 -109 -15 -33 -36
-67 -45 -75 -10 -8 -29 -39 -43 -69 -13 -31 -32 -65 -42 -76 -10 -11 -31 -45
-48 -75 -40 -75 -112 -191 -130 -212 -9 -10 -26 -41 -40 -70 -13 -29 -35 -64
-47 -78 -13 -14 -29 -42 -35 -62 -7 -20 -27 -56 -45 -80 -18 -24 -42 -61 -54
-84 -11 -22 -28 -46 -37 -54 -9 -7 -27 -38 -41 -68 -13 -29 -38 -70 -54 -90
-16 -21 -32 -49 -35 -64 -4 -15 -19 -40 -33 -55 -14 -15 -41 -58 -58 -95 -18
-36 -38 -69 -44 -73 -7 -4 -24 -35 -40 -69 -15 -34 -34 -69 -42 -77 -17 -17
-86 -126 -132 -209 -16 -30 -36 -61 -43 -68 -7 -7 -24 -36 -38 -65 -15 -29
-37 -65 -49 -82 -13 -16 -32 -50 -43 -75 -11 -25 -32 -56 -46 -68 -14 -13 -31
-44 -39 -69 -7 -25 -27 -62 -44 -82 -17 -20 -41 -58 -55 -85 l-24 -48 28 -24
28 -24 884 0 c541 0 893 4 909 10 28 10 65 61 114 159 19 36 40 73 48 82 8 9
25 44 39 79 14 35 34 73 44 84 11 12 29 43 40 69 11 26 34 64 49 85 16 20 35
53 42 72 21 54 106 202 134 231 13 14 32 51 42 82 10 32 26 66 36 77 11 11 32
49 48 85 16 36 40 80 53 98 14 18 33 55 42 82 10 28 31 66 45 85 15 19 30 45
34 58 4 12 18 35 32 51 14 16 38 60 55 97 17 37 39 79 48 93 37 54 107 178
127 226 11 28 37 76 57 107 21 31 37 60 37 63 0 4 13 25 28 48 15 23 33 56 41
72 7 17 22 48 34 70 11 22 35 67 51 100 17 33 43 76 59 96 15 20 35 58 44 85
9 27 28 65 43 84 15 19 36 55 47 80 11 25 27 53 37 62 9 9 30 47 46 83 16 36
40 80 53 98 14 18 30 48 36 67 6 19 29 61 51 94 22 32 40 64 40 71 0 7 14 31
31 55 18 24 39 64 48 90 9 25 30 63 47 84 17 20 36 54 43 75 6 21 22 54 35 73
25 38 69 114 129 226 21 40 45 76 52 79 8 2 24 35 36 71 12 37 36 87 54 112
17 25 38 61 45 80 7 19 25 51 40 70 15 19 37 57 49 84 12 28 32 64 46 81 13
18 33 58 44 90 11 32 29 68 40 79 10 12 30 44 45 73 14 28 30 54 35 57 5 4 21
37 35 75 15 37 38 79 51 91 14 13 34 48 45 77 11 30 34 71 50 91 16 21 37 58
45 82 22 65 100 162 128 158 23 -3 57 -47 114 -148 20 -36 50 -81 67 -101 40
-48 85 -111 116 -165 14 -24 37 -56 52 -73 15 -16 34 -44 43 -63 10 -18 27
-44 40 -58 12 -14 34 -46 48 -72 15 -26 34 -53 42 -62 8 -8 27 -37 40 -65 14
-28 30 -53 35 -56 6 -4 20 -25 31 -48 12 -23 38 -62 59 -87 20 -25 44 -58 53
-75 8 -16 29 -43 46 -59 17 -17 31 -34 31 -38 0 -5 17 -35 39 -66 21 -31 45
-71 54 -89 9 -17 32 -48 52 -68 19 -21 35 -43 35 -50 0 -6 15 -29 34 -51 18
-21 43 -59 56 -84 12 -25 34 -58 47 -75 24 -29 49 -67 120 -178 18 -27 42 -59
53 -70 12 -10 30 -40 41 -64 11 -25 32 -57 47 -72 15 -15 39 -49 55 -76 51
-91 89 -149 112 -170 12 -11 31 -36 40 -55 10 -19 35 -58 56 -86 22 -28 39
-55 39 -61 0 -5 15 -27 33 -49 19 -21 42 -55 53 -74 10 -19 28 -46 40 -60 12
-14 34 -47 49 -75 14 -27 33 -55 42 -62 8 -6 26 -33 40 -60 14 -26 41 -65 59
-86 19 -20 34 -44 34 -52 0 -9 24 -40 54 -71 l54 -56 859 1 c536 0 869 4 885
10 37 14 42 27 20 59 -11 15 -22 37 -26 49 -4 12 -30 52 -58 90 -27 37 -53 75
-56 83 -3 8 -19 31 -36 51 -31 36 -67 90 -117 176 -14 25 -39 56 -54 70 -15
14 -33 40 -40 57 -7 17 -28 49 -45 71 -18 22 -41 58 -52 80 -12 22 -29 47 -38
55 -9 8 -29 38 -44 67 -14 29 -34 57 -45 62 -10 6 -29 32 -41 58 -12 26 -35
64 -51 84 -16 20 -29 41 -29 47 0 9 -77 118 -129 182 -15 20 -38 56 -51 80
-12 25 -34 58 -49 73 -14 16 -37 47 -51 71 -41 69 -100 156 -121 177 -11 11
-30 44 -43 73 -13 29 -29 57 -36 61 -6 4 -31 37 -56 74 -97 147 -117 175 -130
189 -8 8 -23 30 -33 51 -10 20 -29 45 -41 56 -12 11 -34 43 -47 70 -14 28 -34
57 -43 65 -9 8 -29 38 -43 66 -14 28 -37 64 -50 80 -33 41 -127 181 -127 191
0 5 -18 30 -40 57 -22 26 -40 51 -40 55 0 3 -18 29 -41 56 -23 27 -52 70 -65
95 -28 51 -53 88 -118 176 -25 33 -52 77 -61 97 -8 19 -20 39 -26 42 -6 4 -26
31 -44 60 -19 29 -41 59 -49 67 -7 8 -25 37 -40 65 -14 28 -36 60 -49 70 -13
10 -32 34 -42 53 -10 19 -43 73 -74 118 -31 46 -68 104 -82 128 -14 24 -37 56
-51 71 -14 16 -37 51 -51 78 -14 28 -37 61 -51 74 -14 14 -26 30 -26 37 0 7
-15 32 -33 56 -19 24 -46 64 -60 90 -15 25 -37 54 -48 64 -12 10 -32 39 -44
64 -13 25 -33 53 -44 62 -11 10 -34 43 -51 75 -35 66 -61 106 -107 166 -18 23
-46 68 -64 100 -17 31 -35 57 -40 57 -4 0 -20 21 -35 48 -15 26 -37 58 -50 72
-12 14 -33 48 -47 75 -14 28 -35 59 -47 70 -12 11 -29 37 -37 58 -8 20 -28 46
-43 57 -18 13 -35 39 -45 70 -9 28 -29 60 -46 74 -28 24 -59 69 -116 170 -16
26 -43 66 -60 88 -18 22 -33 45 -33 52 0 6 -18 33 -40 60 -22 26 -40 52 -40
56 0 12 -50 70 -60 70 -5 0 -18 -12 -30 -27z"
          />
          <path
            d="M5418 9153 c-16 -4 -18 -20 -18 -149 l0 -144 3863 -2 c2124 -1 5455
-3 7402 -3 l3540 0 0 150 0 150 -7385 1 c-4062 1 -7393 0 -7402 -3z"
          />
          <path
            d="M3979 8089 c-118 -153 -223 -291 -527 -690 -73 -96 -138 -181 -145
-189 -40 -49 -247 -327 -247 -333 0 -4 90 -7 200 -7 l200 0 85 115 86 115 337
-2 337 -3 25 -110 25 -110 208 -3 c114 -1 207 0 207 3 0 4 -11 45 -24 93 -14
48 -66 242 -116 432 -50 190 -104 392 -120 450 -26 94 -60 222 -94 350 l-12
45 -151 3 -151 3 -123 -162z m204 -481 c20 -101 37 -189 37 -195 0 -10 -42
-13 -175 -13 -117 0 -175 4 -175 11 0 5 33 56 73 112 40 56 99 139 131 185 33
45 62 82 65 82 3 0 23 -82 44 -182z"
          />
          <path
            d="M5210 8246 c0 -3 -76 -311 -170 -685 -93 -374 -170 -683 -170 -686 0
-10 1094 -5 1140 5 141 31 278 137 343 266 20 39 67 219 67 256 0 38 -21 86
-54 125 l-26 31 32 20 c83 52 131 131 168 277 45 179 5 295 -124 363 l-51 27
-577 3 c-318 1 -578 0 -578 -2z m969 -354 c-11 -42 -27 -105 -36 -139 l-16
-63 -344 0 c-323 0 -344 1 -339 18 7 22 52 199 60 234 l6 28 333 2 c182 2 337
2 343 0 8 -2 6 -26 -7 -80z m-151 -605 l-33 -137 -343 0 -344 0 6 28 c4 15 19
77 34 139 l27 112 335 3 c184 2 339 0 343 -3 4 -3 -7 -67 -25 -142z"
          />
          <path
            d="M6835 8238 c-2 -7 -81 -318 -173 -690 l-170 -678 474 0 c311 0 491 4
526 11 227 48 448 219 559 431 59 115 119 357 119 485 0 147 -42 249 -140 337
-51 46 -146 92 -219 106 -83 16 -970 13 -976 -2z m878 -378 c48 -32 77 -92 77
-156 0 -27 -13 -100 -29 -161 -43 -169 -102 -247 -224 -296 -48 -19 -74 -21
-310 -23 -216 -2 -259 0 -264 12 -4 12 141 623 154 647 3 4 130 7 282 5 277
-3 278 -3 314 -28z"
          />
          <path
            d="M8352 7803 c-61 -247 -113 -466 -117 -488 -3 -23 -1 -71 5 -109 19
-118 78 -210 179 -274 88 -56 125 -62 425 -62 312 0 349 6 491 78 144 74 294
236 344 372 7 19 64 236 126 483 l112 447 -185 -2 -186 -3 -108 -435 c-59
-239 -113 -445 -119 -457 -19 -35 -69 -83 -113 -106 -39 -21 -54 -22 -272 -22
l-231 0 -36 28 c-61 46 -63 76 -18 262 51 211 171 695 177 718 5 16 -8 17
-179 17 l-185 0 -110 -447z"
          />
          <path
            d="M10105 8238 c-2 -7 -80 -317 -173 -688 l-169 -675 650 -3 c358 -1
652 -1 653 1 7 6 85 330 82 338 -2 6 -187 10 -460 9 -251 0 -459 3 -461 7 -3
5 51 231 119 503 68 272 124 501 124 508 0 9 -43 12 -180 12 -136 0 -182 -3
-185 -12z"
          />
          <path
            d="M12181 7565 c-94 -377 -171 -687 -171 -690 0 -3 210 -5 468 -5 509 0
540 3 670 58 37 16 95 46 128 68 59 38 176 149 226 213 54 68 108 195 145 341
32 123 37 161 37 250 0 84 -4 116 -22 159 -59 149 -183 252 -336 280 -38 7
-229 11 -517 11 l-457 0 -171 -685z m1029 303 c99 -50 116 -124 71 -305 -36
-142 -62 -194 -130 -255 -85 -76 -109 -81 -413 -86 -217 -3 -268 -1 -268 9 0
14 117 494 147 602 l16 57 266 0 c257 0 268 -1 311 -22z"
          />
          <path
            d="M14488 8236 c-7 -7 -66 -82 -130 -167 -65 -85 -224 -293 -355 -464
-130 -170 -306 -402 -392 -515 -85 -113 -160 -208 -165 -212 -6 -4 84 -7 199
-7 l210 0 83 115 84 114 337 0 338 0 27 -115 28 -115 204 0 c112 0 204 3 204
7 0 6 -67 261 -185 698 -65 241 -117 439 -156 593 l-21 82 -149 0 c-111 0
-151 -3 -161 -14z m62 -498 c50 -236 67 -328 62 -332 -3 -4 -83 -6 -179 -6
-124 0 -173 3 -173 12 0 6 17 34 38 62 21 28 81 111 132 184 52 72 97 132 101
132 4 0 13 -24 19 -52z"
          />
          <path
            d="M15295 8238 c-14 -41 -82 -334 -79 -340 2 -3 116 -7 252 -7 201 0
248 -3 250 -14 2 -8 -51 -231 -118 -495 -66 -265 -120 -489 -120 -497 0 -13
26 -15 179 -15 177 0 179 0 185 23 3 12 61 240 127 507 66 266 122 486 124
488 2 2 117 4 255 4 220 1 252 3 259 17 5 9 26 83 46 166 20 82 39 155 42 162
4 11 -126 13 -696 13 -552 0 -703 -3 -706 -12z"
          />
          <path
            d="M17153 8203 c-22 -27 -89 -113 -149 -192 -60 -79 -180 -236 -267
-350 -86 -113 -208 -273 -271 -356 -63 -82 -162 -212 -220 -289 -58 -76 -106
-140 -106 -142 0 -2 90 -4 200 -4 147 0 202 3 210 13 6 6 45 58 86 115 l76
102 338 0 c314 0 339 -1 344 -17 2 -10 14 -62 26 -115 l22 -98 204 0 c112 0
204 3 204 7 0 3 -22 90 -50 192 -27 102 -56 211 -64 241 -142 536 -238 895
-246 915 l-10 25 -144 0 -144 0 -39 -47z m91 -485 c51 -236 64 -303 60 -310
-7 -11 -354 -10 -354 0 0 8 69 108 205 300 33 45 63 82 66 82 4 0 15 -33 23
-72z"
          />
          <path
            d="M18506 8239 c-59 -14 -166 -74 -219 -123 -84 -78 -120 -152 -168
-346 -38 -153 -16 -239 75 -295 l49 -30 384 -3 c211 -1 383 -6 383 -10 0 -4
-14 -67 -32 -139 l-32 -133 -319 0 -319 0 8 33 c14 66 26 62 -169 63 -118 1
-179 -2 -184 -9 -4 -6 -9 -45 -11 -88 -4 -67 -1 -84 21 -132 31 -69 76 -110
146 -137 52 -20 70 -21 455 -18 400 3 401 3 456 27 117 52 205 130 257 227 37
69 93 288 93 361 0 86 -32 138 -110 179 -32 17 -67 19 -418 22 l-384 3 5 22
c42 176 64 253 72 259 5 4 140 8 299 8 l289 0 -6 -43 c-3 -24 -9 -51 -13 -60
-6 -16 9 -17 179 -17 l185 0 12 51 c31 127 -32 257 -150 313 -43 20 -61 21
-420 23 -206 1 -392 -3 -414 -8z"
          />
          <path
            d="M19735 8238 c-3 -7 -55 -213 -116 -458 -108 -433 -111 -447 -107
-535 5 -110 37 -185 108 -256 108 -108 175 -122 540 -117 305 5 313 6 458 82
134 71 271 218 327 352 17 41 235 902 235 929 0 13 -26 15 -179 15 l-179 0
-16 -62 c-9 -35 -57 -227 -106 -428 -50 -201 -96 -378 -102 -394 -17 -43 -65
-94 -112 -119 -38 -20 -57 -22 -256 -25 -239 -4 -274 2 -314 49 -20 24 -26 41
-26 80 0 29 42 219 105 469 58 232 105 423 105 425 0 3 -81 5 -180 5 -136 0
-182 -3 -185 -12z"
          />
          <path
            d="M21376 8238 c-2 -7 -36 -141 -75 -298 -57 -233 -215 -865 -252 -1010
-5 -19 -9 -41 -9 -47 0 -17 1021 -18 1120 -2 91 14 180 60 255 131 74 68 113
139 145 260 32 121 24 194 -26 249 -13 14 -24 29 -24 35 0 5 19 22 43 37 23
16 57 48 75 71 l32 43 0 226 0 226 -37 31 c-73 59 -81 60 -690 60 -434 0 -554
-3 -557 -12z m984 -280 c0 -13 -15 -78 -33 -146 l-32 -123 -339 1 c-187 0
-342 3 -344 5 -5 4 20 107 55 233 l14 52 340 0 339 0 0 -22z m-135 -530 c3 -7
-10 -71 -28 -143 l-33 -130 -348 -3 c-319 -2 -348 -1 -342 14 4 9 20 71 37
137 17 67 33 125 35 129 3 4 156 8 340 8 259 0 336 -3 339 -12z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
